import { Payment } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

import { Payer, PayoutMethod, Receiver } from './rent-payments-plan'

export enum TenantInstalmentStatus {
  created = 'CREATED',
  processing = 'PROCESSING',
  cancelled = 'CANCELLED',
  failed = 'FAILED',
  completed = 'COMPLETED',
  refunded = 'REFUNDED',
}

export enum TenantInstalmentEventTypes {
  INVOICE = 'INVOICE',
}

export enum TenantInstalmentInvoiceEventStatus {
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  VERIFICATION_SUCCEED = 'VERIFICATION_SUCCEED',

  CHARGE_PENDING = 'CHARGE_PENDING',
  CHARGE_FAILED = 'CHARGE_FAILED',
  CHARGE_REFUND_FAILED = 'CHARGE_REFUND_FAILED',
  CHARGE_SUCCEED = 'CHARGE_SUCCEED',

  REFUND_SUCCEED = 'CHARGE_REFUNDED',

  TRANSFER_FAILED = 'TRANSFER_FAILED',
  TRANSFER_REVERSED = 'TRANSFER_REVERSED',

  SETTLED = 'SETTLED',
  CANCELED = 'CANCELED',
}

export enum ReminderIssue {
  INVALID_VERIFICATION_TOKEN = 'INVALID_VERIFICATION_TOKEN',
  NOT_SUFFICIENT_FUND = 'NOT_SUFFICIENT_FUND',
  SUPPORT_REQUIRED = 'SUPPORT_REQUIRED',
  OFFLINE_PAY_IN_METHOD = 'OFFLINE_PAY_IN_METHOD',
  NONE = 'NONE',
}

export const REMINDER_THRESHOLD_DAYS = 3

export type TenantInstalmentEventHistoryError = {
  code: Payment.PaymentError
  transactionId: string
  payInMethodId: string
}

export type TenantInstalmentEventHistory = {
  id: string
  type: TenantInstalmentEventTypes
  status: TenantInstalmentInvoiceEventStatus
  amount: number
  transactionFee?: number
  transferAmount: number
  currency: CurrencyISO
  details: {
    batchId?: string // for payout
  }
  error?: TenantInstalmentEventHistoryError
  createdAt: string
}

export type TenantInstalment = {
  id: string
  rentPaymentsPlanId: string
  status: TenantInstalmentStatus
  chargeDate: string
  tenantUserId: string
  createdAt: string
  updatedAt: string
  events: TenantInstalmentEventHistory[]
  processedWith?: {
    payer: Payer
    receiver: Receiver
    payoutMethod: PayoutMethod
    amount: number
    taxes: Payment.TaxBreakdown[]
    currency: CurrencyISO
    fee: Payment.RentalServiceFee
  }
  reminder?: {
    sentAt: string
    issue?: ReminderIssue
  }
}
