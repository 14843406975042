import { PhoneNumber, User } from '@guiker/base-entity'
import { DeepPartial } from '@guiker/ts-utils'

import { Developer } from './developer'
import { FinancialInfo } from './financial-information'
import { Property } from './property'

export type PropertyOwnership = {
  id: string
  property: DeepPartial<Property>
  owner: User | Developer
  effectiveAt: string
  transferedAt?: string
} //deprecate

//////

export enum PropertyOwnershipType {
  SOLE_OWNERSHIP = 'SOLE_OWNERSHIP',
  CO_OWNERSHIP = 'CO_OWNERSHIP',
  PROPSHARING = 'PROPSHARING',
}

export enum RepresentativeRole {
  AGENT = 'AGENT',
  OWNER = 'OWNER',
  MANAGEMENT = 'MANAGEMENT',
}

export type Representative = {
  userId: string
  firstName: string
  lastName: string
  emailAddress: string
  phone?: PhoneNumber
  ownershipPercentage: number
  role: RepresentativeRole
}

export type HoldingPeriod = {
  from: string // ISODate
  to?: string // ISODate
}

export type NEWPropertyOwnership = {
  id: string
  property: {
    id: string
  }
  type: PropertyOwnershipType
  representatives: Representative[]
  primaryRepresentativeId?: string
  financialInfo?: FinancialInfo
  holdingPeriod?: HoldingPeriod
}
