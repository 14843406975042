export enum RentalServiceFeeType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export type RentalServiceFee = {
  default?: boolean
  type: RentalServiceFeeType
  value: number
}
