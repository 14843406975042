import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { NEWPropertyOwnership } from '../../entity'
import { baseEvent } from '../base'

const basePropertyOwnershipEvent = {
  ...baseEvent,
  entity: 'PROPERTY_OWNERSHIP',
  data: null as unknown as NEWPropertyOwnership,
}

export enum PropertyOwnershipEventTypes {}

export const PropertyOwnershipEventsDefinition = generateEventsDefinition({
  ...basePropertyOwnershipEvent,
  eventTypes: PropertyOwnershipEventTypes,
})

export const PropertyOwnershipEvents = generateEventsFromEnum(basePropertyOwnershipEvent)(PropertyOwnershipEventTypes)
