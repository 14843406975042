import { yup } from '@guiker/yup-util'

import { Event } from './event'

export const eventSchema = yup.object<Event>({
  uuid: yup.string().required(),
  context: yup.string().required(),
  entity: yup.string().required(),
  type: yup.string().required(),

  entityId: yup.string(),
  correlatedRequestId: yup.string(),
  sourceUserId: yup.string(),
  serviceName: yup.string(),
  stage: yup.string(),
  emittedAt: yup.string(),
  data: yup.mixed(),
  eventData: yup.mixed(),
})
