import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { NEWProperty } from '../../entity'
import { baseEvent } from '../base'

const basePropertyEvent = {
  ...baseEvent,
  entity: 'PROPERTY',
  data: null as unknown as NEWProperty,
}

export enum PropertyEventTypes {}

export const PropertyEventsDefinition = generateEventsDefinition({
  ...basePropertyEvent,
  eventTypes: PropertyEventTypes,
})

export const PropertyEvents = generateEventsFromEnum(basePropertyEvent)(PropertyEventTypes)
