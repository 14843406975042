import { Jurisdiction } from './expense'
import * as Ontario from './ontario'
import * as Quebec from './quebec'

export type CanadaJurisdiction = Jurisdiction | Ontario.OntarioJurisdiction | Quebec.QuebecJurisdiction
export * from './operation-cost'
export * from './purchase-cost'
export * from './purchase-taxes'
export * from './base'

export { Ontario, Quebec }
