import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { PropertySaleListing } from '../../entity'
import { paths } from './paths'
import {
  searchListingQuerySchema,
  updateListingAssumptionsSchema,
  updateListingPropertyBuildingDetailSchema,
  updateListingPropertyBuildingSchema,
  updateListingPropertyUnitsSchema,
  updateListingSchema,
  updateListingStatusSchema,
} from './schemas'

const oneListingPath = paths.listing.all.withId()

export const routes = {
  updateListingPropertyBuilding: {
    path: `${oneListingPath}/property/building/address`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyBuildingSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  updateListingPropertyBuildingDetail: {
    path: `${oneListingPath}/property/building`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyBuildingDetailSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  updateListingPropertyUnit: {
    path: `${oneListingPath}/property/units`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyUnitsSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  updateListingAssumptions: {
    path: `${oneListingPath}/assumptions`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingAssumptionsSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  updateListing: {
    path: oneListingPath,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  updateListingStatus: {
    path: `${oneListingPath}/status`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingStatusSchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  readOneListing: {
    path: oneListingPath,
    method: HttpMethod.GET,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  createListing: {
    path: `${paths.listing.all.base()}/create`,
    method: HttpMethod.POST,
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  readAllListings: {
    path: paths.listing.all.base(),
    method: HttpMethod.GET,
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropertySaleListing, {}>(),
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
  },
}
