import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { Plaid } from '../../entity'
import { paths } from './paths'
import { CreateLinkTokenSchema, createLinkTokenSchema } from './schemas'

const BASE_ALL = paths.paymentVerification.all.base()

export const routes = {
  createPlaidLinkToken: {
    path: `${BASE_ALL}/create-link-token`,
    method: HttpMethod.POST,
    payloadValidator: (payload: unknown): Promise<CreateLinkTokenSchema> => createLinkTokenSchema.validate(payload),
    responseValidator: (response: unknown) => Promise.resolve(response as Plaid.LinkTokenResponse),
    authenticated: true,
  },
  createStripeLinkToken: {
    path: `${BASE_ALL}/stripe/create-link-token`,
    method: HttpMethod.POST,
    payloadValidator: (payload: unknown): Promise<CreateLinkTokenSchema> => createLinkTokenSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<string>(),
    authenticated: true,
  },
  exchangePublicToken: {
    path: `${BASE_ALL}/exchange-public-token`,
    method: HttpMethod.POST,
    authenticated: true,
  },
}
