import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

import { BookingStatus } from '../../../entity'

export const readAllBookingsQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object({
    userId: yup.string().nullable(),
    listingId: yup.string().nullable(),
    city: yup.string().nullable(),
    statuses: yup
      .array()
      .of<BookingStatus>(yup.mixed<BookingStatus>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
    searchString: yup.string().nullable(),
    includeSpectator: yup.boolean().nullable(),
  }),
)

export type ReadAllBookingsQueryParamsSchema = yup.InferType<typeof readAllBookingsQueryParamsSchema>
