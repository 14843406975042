import React from 'react'

import { Payments } from '@guiker/icons'
import { getBankByRouting } from '@guiker/money'
import { CreditCardBrand, PayInMethodType } from '@guiker/payment-shared'

import { BankIcon } from '../BankIcon'

type Props = {
  type?: PayInMethodType
  brand?: CreditCardBrand
  routingNumber?: string
}

const PayInMethodIcon: React.FC<Props> = ({ type, brand, routingNumber }) => {
  if (type === PayInMethodType.CREDIT_CARD) {
    switch (brand) {
      case 'MASTERCARD':
        return <Payments.Card.MastercardIcon />
      case 'VISA':
        return <Payments.Card.VisaIcon />
      default:
        return <Payments.Card.NewIcon />
    }
  } else if (
    type === PayInMethodType.DIRECT_DEBIT_EFT ||
    type === PayInMethodType.DIRECT_DEBIT_PAD ||
    type === PayInMethodType.DIRECT_DEBIT_ACH
  ) {
    const bank = getBankByRouting(routingNumber)
    return <BankIcon bank={bank?.name} />
  } else {
    return <Payments.Card.NewIcon />
  }
}

export { PayInMethodIcon }
