import React, { createContext, useState } from 'react'

import { merge } from '@guiker/lodash'
import { initializePayoutMethod, PayoutMethod, PayoutMethodWithPayouts } from '@guiker/payout-shared'
import { renderChildren, UseMutateFunction, useMutation, useQuery } from '@guiker/react-framework'

import { useAdminPayoutApiClient, useAuthenticatedPayoutApiClient } from '../hooks'

type PayoutMethodNestedContextProviderProps = {
  id?: string
  readOnly?: boolean
  isAdmin?: boolean
  children?: React.ReactNode | ((props: PayoutMethodNestedContext) => React.ReactNode)
}

type PayoutMethodNestedContext = {
  isFetching: boolean
  payoutMethod: PayoutMethodWithPayouts | undefined
  refetchPayoutMethod: () => Promise<void>
  updatePayoutMethod: (payoutMethod: Partial<PayoutMethod>) => void
  isSettled: boolean
  decryptSSN: UseMutateFunction<string, unknown, void, unknown>
}

export const PayoutMethodNestedContext = createContext<PayoutMethodNestedContext>(undefined)

const QUERY_PREFIX = 'PayoutMethodNested'

const PayoutMethodNestedContextProvider: React.FC<PayoutMethodNestedContextProviderProps> = ({
  id,
  isAdmin,
  readOnly = false,
  children,
}) => {
  const [payoutMethod, setPayoutMethod] = useState<PayoutMethodNestedContext['payoutMethod']>(
    !id ? initializePayoutMethod() : undefined,
  )
  const [isSettled, setIsSettled] = useState(false)
  const apiClient = isAdmin ? useAdminPayoutApiClient() : useAuthenticatedPayoutApiClient()

  const {
    isLoading: isLoadingPayout,
    isFetching: isFetchingPayout,
    isRefetching: isRefetchingPayout,
    refetch,
  } = useQuery<PayoutMethodWithPayouts>(
    `${QUERY_PREFIX}-readOnePayoutMethod`,
    () => apiClient.readOnePayoutMethod({ pathParams: { payoutMethodId: id } }),
    {
      onSuccess: (response) => {
        const initialized = initializePayoutMethod({ userId: response.userId, payoutMethodType: response.type })
        updatePayoutMethod(merge(initialized, response))
      },
      onSettled: () => setIsSettled(true),
    },
  )

  const updatePayoutMethod = (updatedPayoutMethod: Partial<PayoutMethod>) => {
    const merged = merge(payoutMethod, updatedPayoutMethod)
    setPayoutMethod({ ...merged, capabilities: updatedPayoutMethod.capabilities })
  }

  const { mutateAsync: decryptSSN } = useMutation(
    async () =>
      apiClient.revealPayoutMethodSSN({
        pathParams: { payoutMethodId: id },
      }),
    {
      onSuccess: (res) => {
        updatePayoutMethod({ ...payoutMethod, accountHolder: { ssn: res, ...payoutMethod.accountHolder } })
        return res
      },
    },
  )

  const refetchPayoutMethod = async () => {
    setIsSettled(false)
    await refetch()
  }

  const contextValue = {
    isFetching: isLoadingPayout || isFetchingPayout || isRefetchingPayout,
    isSettled,
    payoutMethod,
    refetchPayoutMethod,
    updatePayoutMethod,
    readOnly,
    decryptSSN,
  }

  return (
    <PayoutMethodNestedContext.Provider value={contextValue}>
      {renderChildren<PayoutMethodNestedContext>(children, contextValue)}
    </PayoutMethodNestedContext.Provider>
  )
}

export { PayoutMethodNestedContextProvider }
