import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { Invoice, ScopeType } from '@guiker/payment-shared'
import { Button, Flex, Modal, useModal, useT } from '@guiker/react-framework'

import { useInvoiceContext } from '../../hooks'
import { AuthButtonContainer, InvoiceDetailPaymentMethod, ViewInvoiceDetail } from '../ViewInvoiceDetail'
import { WireTransferInstruction } from '../WireTransferInstruction'

const WireTransferInstructionButton: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { tShared } = useT({ domain: 'payment', entity: 'wireTransfer' })

  return (
    <>
      <Modal maxWidth={900} title={tShared('title')} open={isOpen} onClose={() => closeModal()}>
        <WireTransferInstruction scopeType={invoice.scope?.type as ScopeType} />
      </Modal>
      <Button onClick={() => openModal()} size='medium' fullWidth>
        {tShared('cta')}
      </Button>
    </>
  )
}

export const PayerContent: React.FC = () => {
  const { invoice, invoiceState, breakdownItems, totalAmount, lastTransaction } = useInvoiceContext()
  const { selected } = usePayInMethodContext()

  return (
    <ViewInvoiceDetail
      invoice={invoice}
      breakdownItems={breakdownItems}
      totalAmount={totalAmount}
      payInMethod={lastTransaction?.payInMethod}
    >
      {invoiceState.isPayable && (
        <Flex flexDirection='column' gap={4}>
          <InvoiceDetailPaymentMethod />
          <AuthButtonContainer invoice={invoice} payInMethod={selected} />
        </Flex>
      )}
      {invoiceState.isPendingWireTransfer && <WireTransferInstructionButton invoice={invoice} />}
    </ViewInvoiceDetail>
  )
}
