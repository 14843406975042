import { Assumptions } from '../entity/investment-assumptions'

export const verifyRentalRevenueAssumptions = (assumptions: Assumptions) => {
  const rentalAssumptions = assumptions.revenue
  const isEstimated = !rentalAssumptions.occupancies?.length
    ? rentalAssumptions.isEstimatedRevenue
    : rentalAssumptions.occupancies.some((o) => !o.isOccupied)
  const totalRentalRevenue = !rentalAssumptions.occupancies?.length
    ? rentalAssumptions.rentalRevenue
    : rentalAssumptions.occupancies.reduce((acc, cur) => cur.monthlyRent + acc, 0)
  return {
    ...assumptions,
    revenue: { ...rentalAssumptions, isEstimatedRevenue: isEstimated, rentalRevenue: totalRentalRevenue },
  }
}
