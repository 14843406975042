import { CountryCode } from '@guiker/phone-number'
import { yup } from '@guiker/yup-util'

import { ListingInquirySource, ListingInquiryStatus, VisitSchedule } from '../../../entity'

export const updateInquirySchema = yup.object({
  listingId: yup.string().required(),
  moveInDate: yup.string(),
  message: yup.string().nullable(),
  rentalOptionId: yup.string().required(),
  user: yup
    .object({
      id: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().required(),
      phone: yup
        .object({
          country: yup.mixed<CountryCode>().required(),
          number: yup.string().required(),
        })
        .required(),
    })
    .required(),
  isBookingNotificationEnabled: yup.boolean().nullable(),
  visitSchedules: yup.array<VisitSchedule>().required(),
  status: yup.mixed<ListingInquiryStatus>().oneOf(Object.values(ListingInquiryStatus)).required(),
  source: yup.mixed<ListingInquirySource>().oneOf(Object.values(ListingInquirySource)).required(),
})

export type UpdateInquirySchema = yup.InferType<typeof updateInquirySchema>
