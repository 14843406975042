import { Features } from './features'
import { Feature } from './types'

export const isWhitelistedEmails = (emailAddress?: string) => {
  if (!emailAddress) return false

  const regex = /.+@guiker\.com$/
  return regex.test(emailAddress.toLowerCase())
}

export const FeaturesConfigurations: Feature[] = [
  /** @description temporary features */
  {
    name: Features.RevokeLesseeSignaturesOnLeaseUpdate,
    enabled: ({ stage }) => stage !== 'production',
  },
  {
    name: Features.NewMobileTabsNavigation,
    enabled: () => false,
  },
  {
    name: Features.DarkAppBar,
    enabled: () => false,
  },
  {
    name: Features.VerifyCertnBackgroundCheck,
    enabled: () => false,
  },
  {
    name: Features.AutoBackgroundCheckOnBookingApplicationSubmit,
    enabled: () => false,
  },
  {
    name: Features.LeaseDocuments,
    enabled: ({ stage }) => stage !== 'production',
  },
  {
    name: Features.PromiseToLease,
    enabled: () => false,
  },
  {
    name: Features.SendRecommendedListingsEmail,
    enabled: ({ stage }) => stage !== 'production',
  },
  /** @description permanent features */
  {
    name: Features.TrustAccountPlaidConnection,
    enabled: ({ user }) => isWhitelistedEmails(user?.emailAddress),
  },
  {
    name: Features.TestPasswordStrength,
    enabled: ({ stage }) => stage === 'production',
  },
]
