import { AuthLevel, buildPaths } from '@guiker/api-definition'

export const paths = buildPaths(
  {
    listing: {},
    user: {
      investorProfile: {},
    },
    investorProfile: {
      stockOrder: {},
      portfolio: {},
    },
    stock: {
      stockOrder: {},
      financialStatements: {},
    },
  },
  AuthLevel.ADMIN,
)
