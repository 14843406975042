import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { NEWBuilding } from '../../entity'
import { baseEvent } from '../base'

const baseBuildingEvent = {
  ...baseEvent,
  entity: 'BUILDING',
  data: null as unknown as NEWBuilding,
}

export enum BuildingEventTypes {
  ADDRESS_UPDATED = 'ADDRESS_UPDATED',
  BUILDING_DETAIL_UPDATED = 'BUILDING_DETAIL_UPDATED',
  CREATION_INITIATED = 'CREATION_INITIATED',
}

export const BuildingEventsDefinition = generateEventsDefinition({
  ...baseBuildingEvent,
  eventTypes: BuildingEventTypes,
})

export const BuildingEvents = generateEventsFromEnum(baseBuildingEvent)(BuildingEventTypes)
