import React from 'react'

import { LinkProps } from '@guiker/react-framework'

import { useInvoiceContext } from '../../hooks'
import { ViewInvoiceDetail } from '../ViewInvoiceDetail'

type ReceiverContentProps = {
  recipientLinkProps?: LinkProps
  topActions?: React.ReactNode
}
export const ReceiverContent: React.FC<ReceiverContentProps> = ({ recipientLinkProps, topActions }) => {
  const { invoice, lastTransaction, breakdownItems, totalAmount } = useInvoiceContext()

  return (
    <ViewInvoiceDetail
      invoice={invoice}
      breakdownItems={breakdownItems}
      totalAmount={totalAmount}
      payInMethod={lastTransaction?.payInMethod}
      recipientLinkProps={recipientLinkProps}
      topActions={topActions}
      isHistoryOpen
    />
  )
}
