import { useContext } from 'react'

import { PayoutMethodNestedContext } from '../context'

export const usePayoutMethodNestedContext = () => {
  const context = useContext(PayoutMethodNestedContext)

  if (context === undefined) {
    throw new Error('usePayoutMethodNestedContext can only be used inside PayoutMethodNestedContextProvider')
  }

  return context
}
