import { generateEventsFromEnum } from '@guiker/event'

import { baseEvent } from '../base'
import { ListingEventTypes, ListingUserEventTypes } from './event-types'

export const BaseLegacyListingEvent = {} as const

export const LegacyListingEvents = generateEventsFromEnum({
  ...baseEvent,
  data: null as unknown as { bookingId: string; userId: string },
  /** @todo update on Java legacy. this is not the right entity */
  entity: 'INQUIRY',
})(ListingEventTypes)

export const LegacyListingUserEvents = generateEventsFromEnum({
  ...baseEvent,
  data: null as unknown as { entityId: string; userId: string },
  entity: 'LISTING_USER',
})(ListingUserEventTypes)
