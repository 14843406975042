import { yup } from '@guiker/yup-util'

import { CountryCode } from '../../../entity'

export const createLinkTokenSchema = yup.object({
  countryCode: yup.mixed().oneOf(Object.values(CountryCode)).required(),
  accessToken: yup.string().optional(),
})

export type CreateLinkTokenSchema = yup.InferType<typeof createLinkTokenSchema>
