import { AuthLevel, generateApiPath } from '@guiker/api-definition'
const developer = generateApiPath({ prefix: AuthLevel.ADMIN, name: 'developer' })

const paths = {
  buildings: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'building' }),
  property: generateApiPath({
    prefix: AuthLevel.ADMIN,
    name: 'property',
    plural: 'properties',
  }),
  propertyOwnership: generateApiPath({ prefix: AuthLevel.ADMIN, name: 'property-ownership' }),
  developer,
  developerNestedProject: generateApiPath({ prefix: developer.all.withId(':developerId'), name: 'project' }),
}

export { paths }
