import { math, numberToString } from '@guiker/lodash'

import { Currency, currency, CurrencyISO } from './currency'

const getCurrencySymbol = (curr: Currency | CurrencyISO, currencySymbol: boolean | 'onlySymbol' = true) => {
  const currency = getCurrency(curr)
  return currencySymbol ? (currencySymbol === 'onlySymbol' ? currency.sign + ' ' : currency.symbol + ' ') : ''
}

const formatter =
  (curr: Currency | CurrencyISO, currencySymbol: boolean | 'onlySymbol' = true, noDecimal = false) =>
  (monetized: number) => {
    const currency = getCurrency(curr)

    if (isNaN(monetized)) {
      return ''
    }

    const stringified = `${noDecimal ? monetized.toFixed(0) : monetized.toFixed(currency.decimalPlace)}`
    const expression = `\\B(?=(\\d{${currency.separator.position}})+(?!\\d))`
    const regex = new RegExp(expression, 'g')
    const final = numberToString(
      monetized,
      noDecimal ? 0 : 2,
      currency.separator.position,
      currency.separator.delimiter,
    )

    stringified.replace(regex, currency.separator.delimiter)

    return `${getCurrencySymbol(currency, currencySymbol)}${final}`
  }

type Options = {
  currencySymbol?: boolean | 'onlySymbol'
  noDecimal?: boolean
  isNegative?: boolean
}

const generateMoney = (amount: number, monetized: number, currency: Currency) => {
  return {
    amount,
    monetized,
    currency,
    toString: <O extends Options>(
      options?: Options | boolean | 'onlySymbol',
      noDecimal?: O extends Options ? boolean : never,
      isNegative?: boolean,
    ) => {
      const { currencySymbol, noDecimal: noDec } =
        typeof options === 'object'
          ? { currencySymbol: options.currencySymbol, noDecimal: options.noDecimal }
          : { currencySymbol: options ?? true, noDecimal: noDecimal ?? false }

      const prefix = isNegative ? '-' : ''

      return `${prefix}${formatter(currency, currencySymbol, noDec)(monetized)}`
    },
  }
}

const isCurrency = (curr: Currency | CurrencyISO): curr is Currency => {
  return !!(curr as Currency)?.decimalPlace
}

const getCurrency = (curr: Currency | CurrencyISO): Currency => {
  return isCurrency(curr) ? curr : currency[curr]
}

const toMonetized = (amount: number, currency: Currency | CurrencyISO) => {
  const decimalPlaces = getCurrency(currency).decimalPlace
  return math.decimal.round(amount / Math.pow(10, decimalPlaces), decimalPlaces)
}

const money = {
  formatter,
  currency,
  getCurrencySymbol,
  toMonetized,
  fromPrice: <P extends { amount: number; currency: CurrencyISO }>(price: P) => {
    const { amount, currency } = price
    return generateMoney(amount, toMonetized(amount, currency), getCurrency(currency))
  },
  fromAmount: (amount: number, currency: Currency | CurrencyISO) => {
    return generateMoney(amount, toMonetized(amount, currency), getCurrency(currency))
  },
  fromAmountString: (amountString: string, currency: Currency | CurrencyISO) => {
    const amount = Number(amountString.replace('.', ''))
    return generateMoney(amount, toMonetized(Number(amount), currency), getCurrency(currency))
  },
  toAmount: (monetized: number, currency: Currency | CurrencyISO) => {
    const amount = Math.round(monetized * Math.pow(10, getCurrency(currency).decimalPlace))
    return generateMoney(amount, monetized, getCurrency(currency))
  },
}

const moneyHelper = (currency: Currency | CurrencyISO) => {
  return {
    formatter: (currencySymbol: boolean | 'onlySymbol' = true, noDecimal = false) =>
      money.formatter(currency, currencySymbol, noDecimal),
    toMonetized: (amount: number) => money.toMonetized(amount, currency),
    fromAmount: (amount: number) => money.fromAmount(amount, currency),
    fromAmountString: (amountString: string) => money.fromAmountString(amountString, currency),
    toAmount: (monetized: number) => money.toAmount(monetized, currency),
  }
}

export { money, moneyHelper }
