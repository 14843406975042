import React from 'react'

import { transferHelper } from '@guiker/payout-app-components'
import { FetcherArg, Flex, PaginatedTable, useT } from '@guiker/react-framework'
import { DateTime, ValueOfUnion } from '@guiker/shared-framework'
import {
  PayoutStatusGroupName,
  RentCollectionPayoutCountByStatus,
  RentCollectionPayoutStatusGroups,
  RentPayoutStatus,
} from '@guiker/statistic-shared'

import { useAuthenticatedStatisticApiClient, useAuthenticationContext } from '../../hooks'
import { RentCollectionPayoutStatusGrouping } from '../RentCollectionPayoutStatusDefinition'
import { useRentCollectionPayoutColumns } from './use-rent-collection-payout-columns'

type Props = {
  date?: string
  header?: React.ReactNode
  selectedStatusGroup?: PayoutStatusGroupName
  isPaginated?: boolean
  setRentCollectionCount?: (value: RentCollectionPayoutCountByStatus[]) => void
  onSelect?: (statuses: ValueOfUnion<RentPayoutStatus>[]) => void
}

export const RentCollectionPerReceivedPayoutTable: React.FC<Props> = ({
  header,
  selectedStatusGroup,
  onSelect,
  ...props
}) => {
  const { date = DateTime.local().toFormat('yyyy-MM'), isPaginated = true } = props
  const { user } = useAuthenticationContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { tMain } = useT({ domain: 'myInvestments', screenName: 'screens.rentCollectionPayout' })

  const fetcher = async ({ statuses, ...paginate }: FetcherArg<ValueOfUnion<RentPayoutStatus>>) => {
    const queryParams = {
      date,
      statuses,
      ...(isPaginated ? paginate : {}),
    }

    const { data, meta } = await statisticApi.readRentCollectionPerPayoutTable({ queryParams })
    const payouts = data.map((payout) => ({ ...payout, transfers: transferHelper.getFullTransfers(payout) }))
    return { data: payouts, meta }
  }

  return (
    <Flex flexDirection='column' gap={2}>
      {header}
      <PaginatedTable
        queryKey={`rentCollectionPerReceivedPayout-${user?.id}-${date}`}
        fetcher={fetcher}
        columns={useRentCollectionPayoutColumns()}
        pagination={{ perPage: 10, sort: 'status', sortOrder: 1 }}
        showPagination={isPaginated}
        isRowCollapsible={true}
        collapsibleOptions={{ isOpen: false }}
        statusGroups={{
          mapper: RentCollectionPayoutStatusGroups,
          onSelect,
          selectedStatusGroupName: selectedStatusGroup,
          groups: [
            {
              name: 'all',
              label: tMain('statusGroup.all'),
            },
            {
              name: 'received',
              label: tMain('statusGroup.received'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.received} />,
            },
            {
              name: 'inTransit',
              label: tMain('statusGroup.inTransit'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.inTransit} />,
            },
            {
              name: 'upcoming',
              label: tMain('statusGroup.upcoming'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.upcoming} />,
            },
            {
              name: 'paidOffline',
              label: tMain('statusGroup.paidOffline'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.paidOffline} />,
            },
          ],
        }}
      />
    </Flex>
  )
}
