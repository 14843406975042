import { config } from '../config'
import { PayoutCapabilitiesType } from '../entity'

export const webappRoutes = {
  payout: {
    path: `/${config.domainName}`,
    withId: function (payoutMethodId: string) {
      return {
        path: `${this.path}/${payoutMethodId}`,
        edit: function () {
          return { path: `${this.path}/edit` }
        },
      }
    },
    create: function () {
      return {
        path: `${this.path}/new?type=${PayoutCapabilitiesType.payout}`,
        investment: `${this.path}/new?type=${PayoutCapabilitiesType.investment}`,
      }
    },
  },
}
