import { buildingRoutes } from './buildings'
import { developerRoutes } from './developer'
import { projectRoutes } from './project'
import { propertyRoutes } from './properties'
import { propertyOwnershipRoutes } from './property-ownership'

export const routes = {
  ...buildingRoutes,
  ...developerRoutes,
  ...projectRoutes,
  ...propertyRoutes,
  ...propertyOwnershipRoutes,
}
