import { Geometry } from '@guiker/geometry'
import { slugify, slugifyLowercase } from '@guiker/lodash'
import { CurrencyISO } from '@guiker/money'
import { ValueOf } from '@guiker/ts-utils'

import { Address } from './address'

export type Geolocation = {
  type: 'POINT'
  coordinates: number[]
}

export type Neighbourhood = {
  slug: string
  zipcodes: string[] | readonly string[]
}

export type Coordinate = {
  longitude: number
  latitude: number
}

type City = {
  slug: string
  disabled?: boolean
  coordinates: Coordinate
  zoom: number
  coverage: Coordinate[] | readonly Coordinate[]
  externalId?: number
  defaultNeighbourhood: string
  neighbourhoods: {
    [key: string]: Neighbourhood
  }
}

type State = {
  stateCode: string
  cities: {
    [key: string]: City
  }
}

type Country = {
  countryCode: string
  currency: CurrencyISO
  states: {
    [key: string]: State
  }
}

type Locations = {
  [key: string]: Country
}

export type Location = {
  neighbourhood?: Neighbourhood
  city: City
  state: State
  country: Country
}

export const DEFAULT_NEIGHBOURHOOD = 'other-neighborhoods'

export const Locations = {
  CA: {
    countryCode: 'CA',
    currency: CurrencyISO.CAD,
    states: {
      ON: {
        stateCode: 'ON',
        cities: {
          toronto: {
            slug: 'toronto',
            zoom: 12,
            coordinates: {
              longitude: -79.3831843,
              latitude: 43.653226,
            },
            coverage: [
              // https://goo.gl/maps/3SKu5bQD8UF497E7A
              // clock-wise from left-top
              { latitude: 43.750652, longitude: -79.641423 },
              { latitude: 43.857181, longitude: -79.168676 },
              { latitude: 43.793163, longitude: -79.111273 },
              { latitude: 43.609491, longitude: -79.330313 },
              { latitude: 43.577664, longitude: -79.545233 },
              { latitude: 43.643412, longitude: -79.612931 },
            ],
            externalId: 2,
            defaultNeighbourhood: 'greater-toronto-area',
            neighbourhoods: {
              'church-and-wellesley': {
                slug: 'church-and-wellesley',
                zipcodes: ['M4Y'],
              },
              'downtown-east': {
                slug: 'downtown-east',
                zipcodes: ['M5A'],
              },
              'downtown-yonge': {
                slug: 'downtown-yonge',
                zipcodes: ['M5G', 'M5B'],
              },
              'downtown-south-harbourfront': {
                slug: 'downtown-south-harbourfront',
                zipcodes: ['M5E', 'M5J', 'M5W'],
              },
              'financial-district': {
                slug: 'financial-district',
                zipcodes: ['M5C', 'M5H', 'M5K', 'M5L', 'M5X'],
              },
              'the-annex-yorkville': {
                slug: 'the-annex-yorkville',
                zipcodes: ['M5R'],
              },
              'bay-street-corridor': {
                slug: 'bay-street-corridor',
                zipcodes: ['M7A', 'M5S'],
              },
              'kensington-market-chinatown': {
                slug: 'kensington-market-chinatown',
                zipcodes: ['M5T'],
              },
              'king-and-spadina-bathurst-quay': {
                slug: 'king-and-spadina-bathurst-quay',
                zipcodes: ['M5V'],
              },
              midtown: {
                slug: 'midtown',
                zipcodes: ['M4S', 'M5N', 'M5P'],
              },
              mimico: {
                slug: 'mimico',
                zipcodes: ['M8V'],
              },
              'liberty-village-king-west': {
                slug: 'liberty-village-king-west',
                zipcodes: ['M6K'],
              },
              'trinity-bellwoods-little-italy': {
                slug: 'trinity-bellwoods-little-italy',
                zipcodes: ['M6G'],
              },
              'greater-toronto-area': {
                slug: 'greater-toronto-area',
                zipcodes: [],
              },
            },
          },
          ottawa: {
            slug: 'ottawa',
            disabled: false,
            zoom: 12,
            coordinates: {
              longitude: -75.69382667541504,
              latitude: 45.40803176513202,
            },
            coverage: [
              // https://goo.gl/maps/pGYJHxMYgjeNQrxH6
              // clock-wise from left-top
              { latitude: 45.414839, longitude: -76.366207 },
              { latitude: 45.510023, longitude: -76.23572 },
              { latitude: 45.519293, longitude: -76.092375 },
              { latitude: 45.372022, longitude: -75.823285 },
              { latitude: 45.40972, longitude: -75.759542 },
              { latitude: 45.423918, longitude: -75.70878 },
              { latitude: 45.456457, longitude: -75.68867 },
              { latitude: 45.538985, longitude: -75.340953 },
              { latitude: 45.364388, longitude: -75.245635 },
              { latitude: 45.309664, longitude: -75.417263 },
              { latitude: 45.177195, longitude: -75.347225 },
              { latitude: 44.963825, longitude: -75.822384 },
            ],
            externalId: 4,
            defaultNeighbourhood: 'other-neighborhoods',
            neighbourhoods: {
              carlington: {
                slug: 'carlington',
                zipcodes: ['K2A'],
              },
              'chinatown-little-italy': {
                slug: 'chinatown-little-italy',
                zipcodes: ['K1R'],
              },
              centretown: {
                slug: 'centretown',
                zipcodes: ['K2P'],
              },
              'downtown-parliament-hill': {
                slug: 'downtown-parliament-hill',
                zipcodes: ['K1P, K1A'],
              },
              'lower-town-byward-market-sandy-hill': {
                slug: 'lower-town-byward-market-sandy-hill',
                zipcodes: ['K1N'],
              },
              'old-ottawa-south': {
                slug: 'old-ottawa-south',
                zipcodes: ['K1S'],
              },
              'the-glebe': {
                slug: 'the-glebe',
                zipcodes: ['K1S'],
              },
              'wellington-west': {
                slug: 'wellington-west',
                zipcodes: ['K1Y'],
              },
              'westboro-village': {
                slug: 'westboro-village',
                zipcodes: ['K1Z'],
              },
              'other-neighborhoods': {
                slug: 'other-neighborhoods',
                zipcodes: [],
              },
            },
          },
        },
      },
      QC: {
        stateCode: 'QC',
        cities: {
          montreal: {
            slug: 'montreal',
            zoom: 12,
            coordinates: {
              longitude: -73.567256,
              latitude: 45.5016889,
            },
            coverage: [
              // clock-wise from left-top
              { latitude: 45.491102, longitude: -73.967057 },
              { latitude: 45.631902, longitude: -73.765955 },
              { latitude: 45.699607, longitude: -73.485587 },
              { latitude: 45.49316, longitude: -73.382834 },
              { latitude: 45.345823, longitude: -73.582468 },
            ],
            externalId: 1,
            defaultNeighbourhood: 'other-neighborhoods',
            neighbourhoods: {
              cdn: {
                slug: 'cdn',
                zipcodes: ['H3S', 'H3T', 'H3V', 'H3W', 'H4A', 'H4B'],
              },
              downtown: {
                slug: 'downtown',
                zipcodes: ['H2Z', 'H3A', 'H3B', 'H3G'],
              },
              'gay-village': {
                slug: 'gay-village',
                zipcodes: ['H2L'],
              },
              griffintown: {
                slug: 'griffintown',
                zipcodes: ['H3C', 'H3J'],
              },
              lasalle: {
                slug: 'lasalle',
                zipcodes: ['H4E', 'H8N', 'H8P', 'H8R'],
              },
              mcgill: {
                slug: 'mcgill',
                zipcodes: ['H2X'],
              },
              'mile-end': {
                slug: 'mile-end',
                zipcodes: ['H2T'],
              },
              'old-port': {
                slug: 'old-port',
                zipcodes: ['H2Y'],
              },
              outremont: {
                slug: 'outremont',
                zipcodes: ['H2V'],
              },
              plateau: {
                slug: 'plateau',
                zipcodes: ['H2H', 'H2J', 'H2W'],
              },
              'quatier-des-spectacles': {
                slug: 'quatier-des-spectacles',
                zipcodes: ['H3A'],
              },
              rosemont: {
                slug: 'rosemont',
                zipcodes: ['H1T', 'H1X', 'H1Y', 'H2G', 'H2S'],
              },
              'saint-henri': {
                slug: 'saint-henri',
                zipcodes: ['H4C'],
              },
              'shaughnessy-village': {
                slug: 'shaughnessy-village',
                zipcodes: ['H3H'],
              },
              verdun: {
                slug: 'verdun',
                zipcodes: ['H4G', 'H4H'],
              },
              westmount: {
                slug: 'westmount',
                zipcodes: ['H3Y', 'H3Z'],
              },
              'other-neighborhoods': {
                slug: 'other-neighborhoods',
                zipcodes: [] as string[],
              },
            },
          },
          gatineau: {
            slug: 'gatineau',
            disabled: false,
            zoom: 12,
            coordinates: {
              longitude: -75.701271,
              latitude: 45.476543,
            },
            coverage: [
              // clock-wise from left-top
              { latitude: 45.472643, longitude: -75.781799 },
              { latitude: 45.49921, longitude: -75.718379 },
              { latitude: 45.511198, longitude: -75.630224 },
              { latitude: 45.474489, longitude: -75.609961 },
              { latitude: 45.452895, longitude: -75.695748 },
              { latitude: 45.424829, longitude: -75.706011 },
              { latitude: 45.417256, longitude: -75.740221 },
            ],
            externalId: 5,
            defaultNeighbourhood: 'other-neighborhoods',
            neighbourhoods: {
              'other-neighborhoods': {
                slug: 'other-neighborhoods',
                zipcodes: [] as string[],
              },
            },
          },
        },
      },
    },
  },
  US: {
    countryCode: 'US',
    currency: CurrencyISO.USD,
    states: {
      IL: {
        stateCode: 'IL',
        cities: {
          chicago: {
            slug: 'chicago',
            externalId: 3,
            zoom: 12,
            coordinates: {
              longitude: -87.623177,
              latitude: 41.881832,
            },
            coverage: [
              // clock-wise from left-top
              { latitude: 42.015267, longitude: -87.832256 },
              { latitude: 42.026489, longitude: -87.657848 },
              { latitude: 41.739187, longitude: -87.513652 },
              { latitude: 41.641764, longitude: -87.520519 },
              { latitude: 41.691007, longitude: -87.744365 },
            ],
            defaultNeighbourhood: 'chicago-metropolitan-area',
            neighbourhoods: {
              'albany-park': {
                slug: 'albany-park',
                zipcodes: ['60625'],
              },
              andersonville: {
                slug: 'andersonville',
                zipcodes: ['60640'],
              },
              'arcadia-terrace': {
                slug: 'arcadia-terrace',
                zipcodes: ['60659'],
              },
              avondale: {
                slug: 'avondale',
                zipcodes: ['60618'],
              },
              bridgeport: {
                slug: 'bridgeport',
                zipcodes: ['60616', '60608'],
              },
              bucktown: {
                slug: 'bucktown',
                zipcodes: ['60622', '60647', '60614'],
              },
              'chinatown-douglas': {
                slug: 'chinatown-douglas',
                zipcodes: ['60616'],
              },
              edgewater: {
                slug: 'edgewater',
                zipcodes: ['60660', '60640'],
              },
              'gold-coast': {
                slug: 'gold-coast',
                zipcodes: ['60610', '60611'],
              },
              'goose-island': {
                slug: 'goose-island',
                zipcodes: ['60622', '60610'],
              },
              'humbodlt-park': {
                slug: 'humbodlt-park',
                zipcodes: ['60622', '60647', '60651'],
              },
              'hyde-park': {
                slug: 'hyde-park',
                zipcodes: ['60615', '60637'],
              },
              'irving-park': {
                slug: 'irving-park',
                zipcodes: ['60618'],
              },
              lakeview: {
                slug: 'lakeview',
                zipcodes: ['60613', '60618', '60657'],
              },
              'lincoln-park': {
                slug: 'lincoln-park',
                zipcodes: ['60614', '60622', '60647', '60610'],
              },
              'lincoln-square': {
                slug: 'lincoln-square',
                zipcodes: ['60625'],
              },
              'logan-square': {
                slug: 'logan-square',
                zipcodes: ['60618', '60647'],
              },
              'noble-square': {
                slug: 'noble-square',
                zipcodes: ['60622'],
              },
              'north-center': {
                slug: 'north-center',
                zipcodes: ['60618', '60613'],
              },
              'north-park': {
                slug: 'north-park',
                zipcodes: ['60625'],
              },
              'old-town': {
                slug: 'old-town',
                zipcodes: ['60610', '60611'],
              },
              pilsen: {
                slug: 'pilsen',
                zipcodes: ['60608', '60616'],
              },
              ravenswood: {
                slug: 'ravenswood',
                zipcodes: ['60625', '60640', '60613'],
              },
              'river-north': {
                slug: 'river-north',
                zipcodes: ['60610', '60611', '60654'],
              },
              'river-west': {
                slug: 'river-west',
                zipcodes: ['60610', '60622'],
              },
              'rogers-park': {
                slug: 'rogers-park',
                zipcodes: ['60645', '60659', '60660', '60626'],
              },
              'roscoe-village': {
                slug: 'roscoe-village',
                zipcodes: ['60618'],
              },
              'south-loop': {
                slug: 'south-loop',
                zipcodes: ['60607, 60605, 60604, 60616'],
              },
              streeterville: {
                slug: 'streeterville',
                zipcodes: ['60611'],
              },
              'the-loop': {
                slug: 'the-loop',
                zipcodes: ['60661', '60606', '60607', '60605', '60604', '60603', '60602', '60601'],
              },
              'ukrainian-village': {
                slug: 'ukrainian-village',
                zipcodes: ['60612', '60622'],
              },
              'university-village': {
                slug: 'university-village',
                zipcodes: ['60607', '60608'],
              },
              uptown: {
                slug: 'uptown',
                zipcodes: ['60613', '60640'],
              },
              'west-loop': {
                slug: 'west-loop',
                zipcodes: ['60622', '60610', '60661', '60606'],
              },
              'wicker-park': {
                slug: 'wicker-park',
                zipcodes: ['60622'],
              },
              'chicago-metropolitan-area': {
                slug: 'chicago-metropolitan-area',
                zipcodes: [] as string[],
              },
            },
          },
        },
      },
      MA: {
        stateCode: 'MA',
        cities: {
          boston: {
            slug: 'boston',
            externalId: 6,
            zoom: 12,
            coordinates: {
              longitude: -71.060954,
              latitude: 42.358606,
            },
            coverage: [] as Coordinate[],
            defaultNeighbourhood: 'metropolitan-area',
            neighbourhoods: {
              'metropolitan-area': {
                slug: 'metropolitan-area',
                zipcodes: [] as string[],
              },
              allston: {
                slug: 'allston',
                zipcodes: ['02134'],
              },
              arlington: {
                slug: 'arlington',
                zipcodes: ['02474', '02476'],
              },
              'back-bay': {
                slug: 'back-bay',
                zipcodes: ['02116'],
              },
              boston: {
                slug: 'boston',
                zipcodes: [
                  '02108',
                  '02109',
                  '02110',
                  '02111',
                  '02113',
                  '02114',
                  '02115',
                  '02118',
                  '02120',
                  '02121',
                  '02123',
                  '02125',
                  '02126',
                  '02131',
                  '02132',
                  '02133',
                  '02136',
                  '02163',
                  '02203',
                  '02210',
                ],
              },
              brighton: {
                slug: 'brighton',
                zipcodes: ['02135'] as string[],
              },
              brookline: {
                slug: 'brookline',
                zipcodes: ['02445', '02446', '02447', '02467'],
              },
              cambridge: {
                slug: 'cambridge',
                zipcodes: ['02138', '02139', '02140', '02141', '02142'],
              },
              charlestown: {
                slug: 'charlestown',
                zipcodes: ['02129'],
              },
              chelsea: {
                slug: 'chelsea',
                zipcodes: ['02150'],
              },
              dorchester: {
                slug: 'dorchester',
                zipcodes: ['02122'],
              },
              'east-boston': {
                slug: 'east-boston',
                zipcodes: ['02128'],
              },
              'jamaica-plain': {
                slug: 'jamaica-plain',
                zipcodes: ['02130'],
              },
              medford: {
                slug: 'medford',
                zipcodes: ['02155'],
              },
              newton: {
                slug: 'newton',
                zipcodes: ['02458', '02459', '02460', '02461', '02462', '02464', '02465', '02466', '02468'],
              },
              quincy: {
                slug: 'quincy',
                zipcodes: ['02169', '02170', '02171'],
              },
              revere: {
                slug: 'revere',
                zipcodes: ['02151'],
              },
              roxbury: {
                slug: 'roxbury',
                zipcodes: ['02119'],
              },
              somerville: {
                slug: 'somerville',
                zipcodes: ['02143', '02144', '02145'],
              },
              'south-boston': {
                slug: 'south-boston',
                zipcodes: ['02127'],
              },
              waltham: {
                slug: 'waltham',
                zipcodes: ['02451', '02452', '02453', '02454', '02455'],
              },
              watertown: {
                slug: 'watertown',
                zipcodes: ['02472'],
              },
            },
          },
        },
      },
    },
  },
} as const

const newCities = ['boston', 'new-york', 'washington-dc', 'seattle']
const defaultCity = 'montreal'

export const getLocationService = () => {
  const getCountry = (countryCode: string): ValueOf<Locations> => {
    return Locations[countryCode?.toUpperCase() as keyof typeof Locations] as ValueOf<Locations>
  }

  const getState = (countryCode: string, stateCode: string) => {
    const country = getCountry(countryCode)

    return country?.states[stateCode?.toUpperCase()]
  }

  const getCity = (countryCode: string, stateCode: string, citySlug: string) => {
    const state = getState(countryCode, stateCode)
    return state?.cities[slugifyLowercase(citySlug)?.toLowerCase()]
  }

  const getCitiesByState = (countryCode: string, stateCode: string) => {
    const state = getState(countryCode, stateCode)
    return state?.cities
  }

  const getNeighbourhood = (countryCode: string, stateCode: string, citySlug: string, neighbourhoodSlug: string) => {
    const city = getCity(countryCode, stateCode, citySlug)
    return city?.neighbourhoods[neighbourhoodSlug?.toLowerCase()]
  }

  const getNeighbourhoodZipcodes = (citySlug: string, neighbourhoodSlugs: string[]) => {
    const city = getCityBySlug(citySlug).city
    let zipcodes: string[] = []
    neighbourhoodSlugs.forEach((slug) => {
      const neigbourhoodZipcodes = city?.neighbourhoods[slug.toLowerCase()].zipcodes
      if (neigbourhoodZipcodes?.length) {
        zipcodes = zipcodes.concat(neigbourhoodZipcodes)
      }
    })
    return zipcodes
  }

  const getLocationFromAddress = (address: Address): Location => {
    const country = getCountry(address.country)
    const state = getState(address.country, address.state)
    const citySlug = address.city ? slugifyLowercase(address.city) : undefined
    const city = citySlug ? getCity(address.country, address.state, citySlug) : undefined

    const neighbourhood = city
      ? (city.neighbourhoods &&
          Object.values(city.neighbourhoods).find(({ zipcodes }) => {
            const regex = new RegExp(zipcodes?.length > 0 ? `^(${zipcodes.join('|')})+` : '.*')
            return address.postalCode?.match(regex)
          })) ||
        city.neighbourhoods[city.defaultNeighbourhood]
      : undefined

    return {
      country,
      state,
      city,
      neighbourhood,
    }
  }

  const getCityBySlug = (citySlug: string): Location => {
    const slugified = citySlug ? slugify(citySlug, { lower: true }) : citySlug
    return getCities().find(({ city }) => city.slug === slugified)
  }

  const getCityByGeoCoordinate = (coordinate: Coordinate): Location => {
    return getCities().find(({ city }) => Geometry.polygon.containsLocation(coordinate, city.coverage as Coordinate[]))
  }

  const getGeoCoordinateByCity = (citySlug: string): Coordinate[] => {
    return getCityBySlug(citySlug)?.city?.coverage as Coordinate[]
  }

  const getCountries = (): Country[] => {
    return Object.values(Locations)
  }

  const getCities = (): Location[] => {
    const cities: Location[] = []

    Object.entries(Locations).forEach(([_, country]: [string, Country]) => {
      Object.entries(country.states).forEach(([_, state]: [string, State]) => {
        Object.entries(state.cities).forEach(([_, city]: [string, City]) => {
          cities.push({
            city,
            state,
            country,
          })
        })
      })
    })

    return cities
  }

  const getCityNeighbourhoods = (countryCode: string, stateCode: string, citySlug: string): Neighbourhood[] => {
    const city = getCity(countryCode, stateCode, citySlug)

    return Object.values(city.neighbourhoods).sort()
  }

  const getZipcodesByCity = (countryCode: string, stateCode: string, citySlug: string): string[] => {
    const city = getCity(countryCode, stateCode, citySlug)

    return Object.values(city.neighbourhoods).reduce((acc, neighbourhood) => {
      return [...acc, ...neighbourhood.zipcodes]
    }, [] as string[])
  }

  const allCitySlugs = getCities().map((location) => location.city.slug)

  return {
    allCitySlugs,
    defaultCity,
    newCities,
    getAll: () => Locations,
    getCity,
    getCitiesByState,
    getCities,
    getCityBySlug,
    getCityByGeoCoordinate,
    getCountries,
    getCountry,
    getCityNeighbourhoods,
    getGeoCoordinateByCity,
    getLocationFromAddress,
    getNeighbourhood,
    getNeighbourhoodZipcodes,
    getState,
    getZipcodesByCity,
  }
}
