import { CostArgs } from '../../../../utils'
import { Base } from '../../base'
import { OperationCost } from './operation-cost'
import { PurchaseCost } from './purchase-cost'
import { PurchaseTaxes } from './purchase-taxes'

export * from './expense'

export const Toronto = {
  purchase: (args: CostArgs) => ({
    ...Base,
    costs: PurchaseCost,
    taxes: PurchaseTaxes(args),
  }),
  operation: {
    costs: OperationCost,
  },
} as const
