import { yup } from '@guiker/yup-util'

import { SQSEvent } from './sqs-event'

export const sqsEventSchema = yup.object<SQSEvent>({
  context: yup.string().required(),
  queueName: yup.string().required(),

  correlatedRequestId: yup.string(),
  serviceName: yup.string(),
  stage: yup.string(),
  batchProcessedAt: yup.string(),

  data: yup.array().of(yup.mixed()),
})
