import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        overview: {
          path: '/overview',
        },
        listings: {
          path: '/listings',
          routes: {
            listListings: '/',
            createListing: '/new',
            byListingId: {
              path: '/:listingId',
              routes: {
                completeCreation: '/edit',
                stock: {
                  path: '/stock',
                  routes: {
                    viewStock: '/',
                    series: {
                      path: '/series',
                      routes: {
                        createSeries: '/new',
                        bySeriesSlug: {
                          path: '/:seriesSlug',
                          routes: {
                            editSeries: '/edit',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        investorProfiles: {
          path: '/investor-profiles',
          routes: {
            listInvestorProfiles: '/',
            byInvestorProfileId: {
              path: '/:investorProfileId',
              routes: {
                reviewInvestorProfile: '/review',
              },
            },
          },
        },
        stocks: {
          path: '/stocks',
          routes: {
            listStocks: '/',
            byStockId: {
              path: '/:stockId',
              routes: {
                viewStock: '/',
                listings: '/listings',
                transactions: '/transactions',
                series: {
                  path: '/series',
                  routes: {
                    createSeries: '/new',
                    bySeriesSlug: {
                      path: '/:seriesSlug',
                      routes: {
                        editSeries: '/edit',
                      },
                    },
                  },
                },
                stockOrder: {
                  path: '/stockOrder',
                  routes: {
                    byStockOrderId: {
                      path: '/:stockOrderId',
                      routes: {
                        viewStockOrder: '/',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
