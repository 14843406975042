import { CountryCode } from '@guiker/phone-number'

const baseURL = 'https://resources.guiker.com/assets'

const staticPagesUrl = `${baseURL}/static-pages`
const aboutUsUrl = `${staticPagesUrl}/about-us/v1`
const homeUrlV1 = `${staticPagesUrl}/home/v1`
const homeUrlV3 = `${staticPagesUrl}/home/v3`
const investmentsUrl = `${staticPagesUrl}/investments`
const termsAndConditions = `${staticPagesUrl}/terms-and-conditions`
const investmentsPropSharingUrl = `${investmentsUrl}/prop-sharing`
const investmentsPropertySaleUrl = `${investmentsUrl}/property-sale`
const investmentsResidentialUrl = `${investmentsUrl}/residential`

export const staticAssets = {
  'about-us': {
    hero: `${aboutUsUrl}/hero.webp`,
    image1: `${aboutUsUrl}/static-content-image-1.webp`,
    image2: `${aboutUsUrl}/static-content-image-2.webp`,
    investors: [
      `${aboutUsUrl}/investor6.webp`,
      `${aboutUsUrl}/investor1.webp`,
      `${aboutUsUrl}/investor2.webp`,
      `${aboutUsUrl}/investor3.webp`,
      `${aboutUsUrl}/investor4.webp`,
      `${aboutUsUrl}/investor5.webp`,
    ],
  },
  careers: {
    hero: `${baseURL}/static-pages/careers/v1/hero.webp`,
    image1: `${baseURL}/static-pages/careers/v1/image1.webp`,
    image2: `${baseURL}/static-pages/careers/v1/image2.webp`,
    testimonial: `${baseURL}/static-pages/careers/v1/testimonial.webp`,
  },
  home: {
    v1: {
      explore: {
        toronto: `${homeUrlV1}/toronto.webp`,
        montreal: `${homeUrlV1}/montreal.webp`,
        chicago: `${homeUrlV1}/chicago.webp`,
        ottawa: `${homeUrlV1}/ottawa.webp`,
      },
    },
    v3: {
      hero: {
        rent: `${homeUrlV3}/hero-rent.png`,
        invest: `${homeUrlV3}/PhoneNotifications.svg`,
        services: `${homeUrlV3}/Dashboard.svg`,
      },
      sections: {
        section1: `${homeUrlV3}/PhoneMapAnimation.svg`,
        section2: `${homeUrlV3}/PhoneNotifications.svg`,
        section3: `${homeUrlV3}/Dashboard.svg`,
      },
    },
  },
  investments: {
    header: `${investmentsUrl}/header.webp`,
    propSharing: {
      willow: `${investmentsPropSharingUrl}/willow-bg.webp`,
      hero: `${investmentsPropSharingUrl}/hero.webp`,
      block1: `${investmentsPropSharingUrl}/block1.webp`,
      block2: `${investmentsPropSharingUrl}/block2.webp`,
      block3: `${investmentsPropSharingUrl}/block3.webp`,
    },
    propertySale: {
      hero: `${investmentsPropertySaleUrl}/hero.webp`,
    },
    residential: {
      hero: `${investmentsResidentialUrl}/hero.webp`,
    },
  },
  investors: {
    heroImage: `${baseURL}/static-pages/investors/v1/hero.webp`,
    howItWorks: `${baseURL}/static-pages/investors/v1/howItWorks.webp`,
    pattern: `${baseURL}/static-pages/investors/v1/pattern.svg`,
    partners: {
      aquilini: `${baseURL}/static-pages/investors/v1/aquilini.webp`,
      brivia: `${baseURL}/static-pages/investors/v1/brivia.webp`,
      cogir: `${baseURL}/static-pages/investors/v1/cogir.webp`,
      horizon: `${baseURL}/static-pages/investors/v1/horizon.webp`,
      montes: `${baseURL}/static-pages/investors/v1/montes-associates.webp`,
    },
    section: {
      section1: {
        url: `${baseURL}/static-pages/investors/v2/willow1.webp`,
      },
      section2: {
        url: `${baseURL}/static-pages/investors/v2/willow2.webp`,
      },
      section3: {
        url: `${baseURL}/static-pages/investors/v2/willow3.webp`,
      },
    },
    services: {
      service1: {
        url: `${baseURL}/static-pages/investors/v1/service1.webp`,
        width: 560,
      },
      service2: {
        url: `${baseURL}/static-pages/investors/v1/service2.webp`,
        width: 415,
      },
      service3: {
        url: `${baseURL}/static-pages/investors/v1/service3.webp`,
        width: 500,
      },
    },
  },
  landlord: {
    hero: `${baseURL}/static-pages/landlord/v1/hero.webp`,
    image1: `${baseURL}/static-pages/landlord/v1/image1.webp`,
    image2: `${baseURL}/static-pages/landlord/v1/image2.webp`,
    image3: `${baseURL}/static-pages/landlord/v1/image3.webp`,
    customerStories: `${baseURL}/static-pages/landlord/v1/customer-stories.webp`,
    customerStoriesPdf: `${baseURL}/static-pages/landlord/v1/customer-stories.pdf`,
    testimonial: `${baseURL}/static-pages/landlord/v1/testimonial.webp`,
  },
  root: {
    meta: `${staticPagesUrl}/meta.png`,
    wechat: `${baseURL}/main/icon-social-wechat-qr.jpg`,
  },
  services: {
    heroImage: `${baseURL}/static-pages/services/v1/HeroBackgroundImage.png`,
    feature1: `${baseURL}/static-pages/services/v1/feature1Image.png`,
    feature2: `${baseURL}/static-pages/services/v1/feature2Image.png`,
    feature3: `${baseURL}/static-pages/services/v1/feature3Image.png`,
    landlordTestimonial: `${baseURL}/static-pages/services/v1/landlordCaseStudy.pdf`,
    agentTestimonial: `${baseURL}/static-pages/services/v1/agentCaseStudy.pdf`,
  },
  'tenant-preference': {
    image1: `${baseURL}/tenant-preference/image1.webp`,
    image2: `${baseURL}/tenant-preference/image2.webp`,
  },
  'tenant-application': {
    image1: `${baseURL}/tenant-application/image1.webp`,
  },
  'terms-and-conditions': {
    invest: `${termsAndConditions}/invest_terms_and_services.pdf`,
  },
  flags: (country: CountryCode) => {
    return `${baseURL}/flags/${country.toLowerCase()}.svg`
  },
  payment: {
    bulkCreateInvoices: `${baseURL}/payment/template/CSV+Bulk+Invoice+Creation.csv`,
  },
}
