import React, { PropsWithChildren } from 'react'

import { Invoice, PayInMethod } from '@guiker/payment-shared'
import { Flex, H2, LinkProps, PageSection2, PageSection4, useDateFormatter } from '@guiker/react-framework'
import { currency as baseCurrency, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { BreakdownItem } from '../../utils'
import { InvoiceReason } from '../InvoiceReason'
import {
  FailReason,
  InvoiceDetailInfo,
  InvoiceDetailStatusChip,
  InvoiceItem,
  PaymentHistory,
  WireTransferNotice,
} from './components'

type ViewInvoiceDetailProps = PropsWithChildren & {
  invoice: Invoice
  payInMethod?: PayInMethod
  breakdownItems: BreakdownItem[]
  totalAmount: number
  isHistoryOpen?: boolean
  recipientLinkProps?: LinkProps
  topActions?: React.ReactNode
}

export const ViewInvoiceDetail: React.FC<ViewInvoiceDetailProps> = ({
  children,
  invoice,
  payInMethod,
  breakdownItems,
  totalAmount,
  isHistoryOpen = false,
  recipientLinkProps,
  topActions,
}) => {
  const { tShared } = useT({})
  const { formatDate } = useDateFormatter()
  const { customer, currency, transactions, createdAt } = invoice

  const paymentTransactions = transactions?.filter(({ intent }) => intent !== 'TRANSFER')

  return (
    <PageSection2
      title={
        <Flex gap={2}>
          <H2 mb={0}>{tShared(`paymentDetails`)}</H2>
          <InvoiceDetailStatusChip invoice={invoice} withDate />
        </Flex>
      }
      topActions={topActions}
    >
      <FailReason />
      <WireTransferNotice />
      <InvoiceDetailInfo
        customer={customer}
        recipientLinkProps={recipientLinkProps}
        issueDate={formatDate(invoice.dueBy || createdAt)}
        invoiceFor={<InvoiceReason invoice={invoice} />}
        payInMethod={payInMethod}
      />

      <PageSection4 title={tShared(`breakdown.header`)}>
        <Flex flexDirection='column' gap={1}>
          {breakdownItems.map(({ label, price, color = 'textPrimary' }) => (
            <InvoiceItem label={label} value={price} color={color} key={label} />
          ))}
          <InvoiceItem
            bold
            label={tShared(`breakdown.totalAmount`)}
            value={money.fromAmount(totalAmount, baseCurrency[currency]).toString(true)}
          />
        </Flex>
      </PageSection4>

      {paymentTransactions?.length > 0 && <PaymentHistory isOpen={isHistoryOpen} transactions={paymentTransactions} />}
      {children}
    </PageSection2>
  )
}
