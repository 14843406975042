import React, { useEffect, useState } from 'react'

import { PayInMethodType, SupportedCountries } from '@guiker/payment-shared'
import { CountryCode, PlaidLink } from '@guiker/payment-verification-components'
import {
  Box,
  ButtonWithLoader,
  clsx,
  Divider,
  Grid,
  makeStyles,
  PSmall,
  SecondaryButton,
  TextField,
  theme,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'

import { ProfileForm } from '../components/ProfileForm'

const useStyles = makeStyles({
  gridContainer: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    width: 'initial',
    '& > div': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const DirectDebitACHCreationForm: React.FC<{
  isProcessing: boolean
  closeForm: () => void
  isManualInput?: boolean
}> = ({ isProcessing, closeForm, isManualInput = false }) => {
  const tPrefix = 'common-payment:directDebit'

  const { t } = useTranslation(['common, common-payment'])
  const classes = useStyles()
  const publicToken = useWatch({ name: 'publicToken', defaultValue: null })
  const formContext = useFormContext()

  const handleOnSelect = ({ publicToken, accountId }) => {
    formContext.setValue(`publicToken`, publicToken)
    formContext.setValue(`bankAccountId`, accountId)
    formContext.setValue(`payInMethodType`, PayInMethodType.DIRECT_DEBIT_ACH)
  }

  const [isReadyToAdd, setIsReadyToAdd] = useState(false)
  const bankAccount = useWatch({
    name: 'bankAccount',
    defaultValue: {
      routingNumber: undefined,
      wireRouting: undefined,
      accountNumber: undefined,
    },
  })

  useEffect(() => {
    const bankAccountReady = bankAccount.routingNumber && bankAccount.wireRouting && bankAccount.accountNumber
    setIsReadyToAdd(isManualInput ? bankAccountReady : !!publicToken)
  }, [isManualInput, publicToken, bankAccount])

  return (
    <>
      <ProfileForm country={SupportedCountries.UnitedStates} />
      <Divider mb={2} />
      {isManualInput ? (
        <>
          <Grid container className={classes.gridContainer}>
            <Grid item sm={4} xs={12}>
              <TextField
                required
                maxWidth='100%'
                label={t(`${tPrefix}.ACH.accountNumber`)}
                name='bankAccount.accountNumber'
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                required
                maxWidth='100%'
                label={t(`${tPrefix}.ACH.routingNumber`)}
                name='bankAccount.routingNumber'
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                required
                maxWidth='100%'
                label={t(`${tPrefix}.ACH.wireRouting`)}
                name='bankAccount.wireRouting'
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box display='none'>
            <TextField type='hidden' name='bankAccountId' />
            <TextField type='hidden' name='publicToken' />
            <TextField type='hidden' name='payInMethodType' />
          </Box>

          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <PSmall color={60}>{t('common-payment:connector.description')}</PSmall>
              <PlaidLink
                countryCode={CountryCode.UnitedStates}
                label={t('common-payment:connector.link')}
                onSelect={handleOnSelect}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container className={clsx(classes.gridContainer, classes.buttonsContainer)}>
        <Grid item>
          <SecondaryButton fullWidth type='button' onClick={closeForm}>
            {t('common:actions.cancel')}
          </SecondaryButton>
        </Grid>
        <Grid item>
          <ButtonWithLoader fullWidth type='submit' isLoading={isProcessing} disabled={isProcessing || !isReadyToAdd}>
            {t('common:actions.add')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  )
}

export { DirectDebitACHCreationForm }
