import React, { useMemo } from 'react'

import { TextFieldProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { CountryCode, getCountries } from '@guiker/phone-number'
import { Dropdown, makeStyles, theme } from '@guiker/react-framework'
import { useStaticAssetContext } from '@guiker/static-asset-context'

type CountryDropdownProps = TextFieldProps & {
  label?: string
  readOnly?: boolean
  required?: boolean
  maxWidth?: string | number
  supportedCountries?: CountryCode[]
  value?: string
}

const useStyles = makeStyles({
  flags: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.grey[30],
  },
})

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  label,
  defaultValue,
  readOnly = false,
  supportedCountries = [],
  name,
  maxWidth,
  required,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles({ label })
  const { getAsset } = useStaticAssetContext()

  const options = useMemo(
    () =>
      (supportedCountries.length ? supportedCountries : getCountries()).map((key) => ({
        value: key as string,
        endAdornment: <img className={classes.flags} src={getAsset('flags')(key)} alt={key} />,
        label: `${key} / ${t(`countries.${key}.name`)}`,
      })),
    [supportedCountries],
  )

  return (
    <Dropdown
      mb={0}
      readOnly={readOnly}
      disabled={disabled}
      name={name}
      defaultValue={defaultValue}
      options={options}
      maxWidth={maxWidth}
      required={required}
      label={label ?? t('address.country')}
      {...props}
    />
  )
}
export { CountryDropdown }
