import React from 'react'

import { LinkProps } from '@guiker/react-framework'
import { User } from '@guiker/shared-framework'

import { useInvoiceContext } from '../../hooks'
import { PayerContent } from './PayerContent'
import { ReceiverContent } from './ReceiverContent'

type InvoiceContentProps = {
  user?: User
  recipientLinkProps?: LinkProps
  topActions?: React.ReactNode
}

export const InvoiceContent: React.FC<InvoiceContentProps> = ({ user, recipientLinkProps, topActions }) => {
  const { invoice } = useInvoiceContext()

  const isPayer = invoice?.customer.id === user?.id

  if (!invoice) return <></>

  return isPayer ? (
    <PayerContent />
  ) : (
    <ReceiverContent recipientLinkProps={recipientLinkProps} topActions={topActions} />
  )
}
