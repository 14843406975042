import React from 'react'

import { Customer, PayInMethod } from '@guiker/payment-shared'
import { Flex, Link, LinkProps, P, PBold, PSmall } from '@guiker/react-framework'
import { isString, optionalConcat } from '@guiker/shared-framework'

import { useT } from '../../../i18n'
import { PayInMethodLabel } from '../../PayInMethodLabel/PayInMethodLabel'

type DetailsProps = {
  i18nKey: string
  value: string | React.ReactElement
  secondaryValue?: string
  alignItems?: 'flex-start' | 'flex-end'
  linkProps?: LinkProps
}

type InvoiceDetailInfoProps = {
  customer: Customer
  issueDate: string
  payInMethod?: PayInMethod
  invoiceFor?: React.ReactElement
  isMobile?: boolean
  recipientLinkProps?: LinkProps
}

const Details: React.FC<DetailsProps> = ({ i18nKey, value, secondaryValue, alignItems = 'flex-start', linkProps }) => {
  const { tShared } = useT({})
  const textAlign = alignItems === 'flex-end' ? 'right' : 'left'

  let content = (
    <Flex flexDirection='column' alignItems={alignItems}>
      {isString(value) ? (
        <P mb={0} textAlign={textAlign}>
          {value}
        </P>
      ) : (
        value
      )}
      {secondaryValue && (
        <PSmall mb={0} color={60} textAlign={textAlign}>
          {secondaryValue}
        </PSmall>
      )}
    </Flex>
  )

  if (linkProps) {
    content = <Link {...linkProps}>{content}</Link>
  }

  return (
    <Flex flexDirection='column' gap={1}>
      <PBold textAlign={textAlign} mb={0}>
        {tShared(i18nKey)}
      </PBold>
      {content}
    </Flex>
  )
}

export const InvoiceDetailInfo: React.FC<InvoiceDetailInfoProps> = ({
  customer,
  issueDate,
  payInMethod,
  invoiceFor,
  recipientLinkProps,
}) => {
  const customerName = optionalConcat([customer.firstName, customer.lastName], ' ')

  return (
    <Flex gap={4} flexDirection='column' justifyContent='center'>
      <Flex gap={2} flexWrap='wrap' justifyContent='space-between'>
        <Details
          i18nKey='info.recipient'
          value={customerName}
          secondaryValue={customer.emailAddress}
          linkProps={recipientLinkProps}
        />
        <Details i18nKey='info.issueDate' value={issueDate} alignItems='flex-end' />
      </Flex>
      <Flex gap={2} flexWrap='wrap' justifyContent='space-between'>
        {invoiceFor ? <Details i18nKey='info.reason' value={invoiceFor} /> : <div />}
        {payInMethod && (
          <Details i18nKey='info.method' value={<PayInMethodLabel payInMethod={payInMethod} />} alignItems='flex-end' />
        )}
      </Flex>
    </Flex>
  )
}
