import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { PayInMethodType } from '@guiker/payment-shared'

import { StripeAppModal } from '../CreditCard'
import { DirectDebitModal } from '../DirectDebit'

type PayInMethodModalsProps = {}

export const PayInMethodModals: React.FC<PayInMethodModalsProps> = ({}) => {
  const { allowedTypes, region } = usePayInMethodContext()

  return (
    <>
      {allowedTypes.includes(PayInMethodType.CREDIT_CARD) && <StripeAppModal region={region} />}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_ACH) && <DirectDebitModal type='ACH' />}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_EFT) && <DirectDebitModal type='EFT' />}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_PAD) && <DirectDebitModal type='PAD' />}
    </>
  )
}
