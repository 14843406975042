type SelectMap<T> = Record<string, T> & { default: T }

const when =
  <T>(testCase: string, returnValue: T) =>
  (value: string) => {
    return testCase === value && returnValue
  }

const select =
  <T>(list: ((value: string) => T)[]) =>
  (value: string) =>
    list.reduce((acc, next) => acc || next(value), null as T)

export const selectFromMap = <T = unknown>(map: SelectMap<T>) => {
  const list = Object.entries(map).map(([key, value]) => (key === 'default' ? () => value : when(key, value)))

  return select(list)
}
