import { yup } from '@guiker/yup-util'

import { SupportedCountries } from '../../../entity'
import { profileSchema } from '../../schemas'

const createDirectDebitEFTPayloadSchema = yup.object({
  region: yup.string().oneOf(Object.values(SupportedCountries)).default(SupportedCountries.Canada).required(),
  bankAccount: yup.object({
    institutionId: yup.string().min(3).max(3).required(),
    transitNumber: yup.string().min(5).max(5).required(),
    accountNumber: yup.string().min(5).max(12).required(),
  }),
  profile: profileSchema.required(),
})

type CreateDirectDebitEFTPayloadSchema = yup.InferType<typeof createDirectDebitEFTPayloadSchema>

export { createDirectDebitEFTPayloadSchema, CreateDirectDebitEFTPayloadSchema }
