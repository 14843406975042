import React from 'react'

import { usePayInMethodContext, usePaymentApiClient } from '@guiker/payment-context'
import { AuthApi } from '@guiker/payment-shared'
import { ApiForm, Box, Modal, Payments, useScreenSize, useTranslation, yupResolver } from '@guiker/react-framework'
import { RecaptchaProvider, useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'

import { DirectDebitACHCreationForm } from './DirectDebitACHCreationForm'
import { DirectDebitEFTCreationForm } from './DirectDebitEFTCreationForm'
import { DirectDebitPADCreationForm } from './DirectDebitPADCreationForm'

type Props = {
  closeForm: () => void
  type: 'ACH' | 'EFT' | 'PAD'
}

const Content: React.FC<Props> = ({ closeForm, type }) => {
  const resolver = yupResolver(
    (AuthApi.Schemas.createDirectDebitWithVerificationPayloadSchema as any).pick([
      'bankAccountId',
      'publicToken',
      'profile',
    ]),
  )
  const apiClient = usePaymentApiClient()
  const { region, onPaymentMethodAdded } = usePayInMethodContext()
  const { executeRecaptcha } = useRecaptcha()

  const onSubmit = async (payload: Required<AuthApi.Schemas.CreateDirectDebitWithVerificationPayloadSchema>) => {
    const recaptchaToken = await executeRecaptcha(RecaptchaAction.createDirectDebit)
    return apiClient.createDirectDebitWithVerification({ payload: { ...payload, recaptchaToken, region } })
  }

  const onSuccess = async (p) => {
    onPaymentMethodAdded(p)

    closeForm()
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{ resolver }}
      apiOptions={{ onSuccess }}
      formName={'CreateDirectDebitForm'}
    >
      {({ isLoading, isSuccess }) => (
        <>
          {type === 'EFT' && <DirectDebitEFTCreationForm isProcessing={isLoading || isSuccess} closeForm={closeForm} />}
          {type === 'ACH' && <DirectDebitACHCreationForm isProcessing={isLoading || isSuccess} closeForm={closeForm} />}
          {type === 'PAD' && <DirectDebitPADCreationForm isProcessing={isLoading || isSuccess} closeForm={closeForm} />}
        </>
      )}
    </ApiForm>
  )
}

type DirectDebitModalProps = {
  type: 'ACH' | 'EFT' | 'PAD'
}

const DirectDebitModal: React.FC<DirectDebitModalProps> = ({ type }) => {
  const { t } = useTranslation('common')
  const { isSm } = useScreenSize()
  const { modalManager } = usePayInMethodContext()
  const { isOpen, closeModal } = modalManager.addDirectDebit

  return (
    <Modal
      fullScreen={!isSm}
      open={isOpen}
      onClose={closeModal}
      title={t('common-payment:directDebit.title')}
      maxWidth={750}
      disableEnforceFocus={true}
      disableBackdropClick
    >
      <Box my={2}>
        <Payments.Bank.BankIcon />
      </Box>

      <RecaptchaProvider>
        <Content type={type} closeForm={closeModal} />
      </RecaptchaProvider>
    </Modal>
  )
}

export { DirectDebitModal }
