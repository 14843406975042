import { getLocationService } from '@guiker/base-entity'
import { selectFromMap } from '@guiker/lodash'
import { Building } from '@guiker/real-estate-shared'
import { ValueOf } from '@guiker/ts-utils'

import { InvestmentAssumptions } from '../entity'

export const defaultCostsBuilder = (building: Building) => {
  const { city, state } = getLocationService().getCityBySlug(building.address.city)
  const citySlug = `${city.slug}-${state.stateCode.toLowerCase()}`

  return {
    citySlug,
    buildPurchaseCosts: (args: InvestmentAssumptions.CostArgs) => {
      const selectCost = selectFromMap<ValueOf<InvestmentAssumptions.PurchaseConfiguration>>({
        ...InvestmentAssumptions.buildPurchaseConfiguration(args),
        default: {
          costs: {},
          taxes: {},
          price: null,
        } as ValueOf<InvestmentAssumptions.PurchaseConfiguration>,
      })
      return selectCost(citySlug)
    },
    buildOperationCosts: () => {
      const selectCost = selectFromMap<ValueOf<typeof InvestmentAssumptions.OperationConfiguration>>({
        ...InvestmentAssumptions.OperationConfiguration,
        default: {
          costs: {} as any,
        },
      })
      return selectCost(citySlug)
    },
  }
}
