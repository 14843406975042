import { routesBuilder } from '@guiker/shared-framework'

import { Invoice } from '../../../../entity'
import { paths } from '../../paths'
import { cancelOneInvoicePayloadSchema, chargeInvoicePayloadSchema, verifyOneInvoicePayloadSchema } from '../schemas'

const basePath = paths.invoice.all.withId(':invoiceId')
const { buildPut, buildPost, buildGet } = routesBuilder<Invoice>()({ basePath })

export const routes = {
  readOneInvoice: buildGet({}),
  readLastInvoiceByScope: buildGet({
    path: paths.invoice.one.withScope(),
  }),
  refundOneInvoice: buildPost({
    path: '/refund',
  }),
  cancelOneInvoice: buildPut({
    path: '/cancel',
    payloadSchema: cancelOneInvoicePayloadSchema,
  }),
  chargeOneInvoiceOffline: buildPut({
    path: '/charge-offline',
  }),
  undoOfflineCharge: buildPut({
    path: '/undo-offline-charge',
  }),
  approveOfflineCharge: buildPut({
    path: '/approve-offline-charge',
  }),
  chargeInvoice: buildPost({
    path: '/charge',
    payloadSchema: chargeInvoicePayloadSchema,
  }),
  verifyOneInvoice: buildPut({
    path: '/verify',
    payloadSchema: verifyOneInvoicePayloadSchema,
    response: null as void,
  }),
} as const
