import React, { useEffect, useState } from 'react'

import { PayInMethodType, SupportedCountries } from '@guiker/payment-shared'
import { CountryCode, StripeLink } from '@guiker/payment-verification-components'
import {
  Box,
  ButtonWithLoader,
  Divider,
  Flex,
  PBig,
  PSmall,
  SecondaryButton,
  TextField,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'

import { ProfileForm } from '../components/ProfileForm'

const DirectDebitPADCreationForm: React.FC<{
  isProcessing: boolean
  closeForm: () => void
}> = ({ isProcessing, closeForm }) => {
  const { t } = useTranslation(['common, common-payment'])
  const bankAccountId = useWatch({ name: 'bankAccountId', defaultValue: null })
  const formContext = useFormContext()

  const handleOnSelect = ({ publicToken, accountId }: { publicToken: string; accountId: string }) => {
    formContext.setValue(`publicToken`, publicToken)
    formContext.setValue(`bankAccountId`, accountId)
    formContext.setValue(`payInMethodType`, PayInMethodType.DIRECT_DEBIT_PAD)
  }

  const [isReadyToAdd, setIsReadyToAdd] = useState(false)
  useEffect(() => {
    setIsReadyToAdd(!!bankAccountId)
  }, [bankAccountId])

  return (
    <Flex flexDirection='column' gap={2}>
      <ProfileForm country={SupportedCountries.Canada} />
      <Divider />

      <Box display='none'>
        <TextField type='hidden' name='bankAccountId' />
        <TextField type='hidden' name='publicToken' />
        <TextField type='hidden' name='payInMethodType' />
      </Box>

      <Flex flexDirection='column' gap={2} mb={4}>
        <PSmall color={60}>{t('common-payment:connector.description')}</PSmall>
        {isReadyToAdd && <PBig mb={0}>{t('common-payment:connector.linked')}</PBig>}
        <StripeLink
          countryCode={CountryCode.Canada}
          label={t('common-payment:connector.link')}
          onSelect={handleOnSelect}
        />
      </Flex>

      <Flex gap={2}>
        <SecondaryButton fullWidth type='button' onClick={closeForm}>
          {t('common:actions.cancel')}
        </SecondaryButton>
        <ButtonWithLoader fullWidth isLoading={isProcessing} type='submit' disabled={isProcessing || !isReadyToAdd}>
          {t('common:actions.add')}
        </ButtonWithLoader>
      </Flex>
    </Flex>
  )
}

export { DirectDebitPADCreationForm }
