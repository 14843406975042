import React from 'react'

import { ExportFetcher, FetcherArg, PaginatedTable, useT } from '@guiker/react-framework'
import { CountryCode, DateTime } from '@guiker/shared-framework'
import {
  CSVBody,
  RentCollectionCountByStatus,
  RentCollectionKpiKey,
  RentCollectionKpiStatusGroups,
  RentCollectionStatus,
  RentCollectionStatusGroupName,
  RentCollectionStatusGroups,
} from '@guiker/statistic-shared'

import { useAuthenticatedStatisticApiClient, useAuthenticationContext } from '../../hooks'
import { Flex, RentCollectionStatusGrouping } from '../'
import { useRentCollectionColumns } from './use-rent-collection-columns'

type Props = {
  region: CountryCode
  date?: string
  header?: React.ReactNode
  selectedStatusGroup?: RentCollectionStatusGroupName
  isPaginated?: boolean
  listingId?: string
  unitId?: string
  setRentCollectionCount?: (value: RentCollectionCountByStatus[]) => void
  onSelect?: (statuses: RentCollectionStatus[]) => void
}

export const RentCollectionPerTenantTable: React.FC<Props> = ({
  header,
  region,
  selectedStatusGroup,
  onSelect,
  ...props
}) => {
  const { date = DateTime.local().toFormat('yyyy-MM'), isPaginated = true, listingId, unitId } = props
  const { user } = useAuthenticationContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { tMain } = useT({ domain: 'myInvestments', screenName: 'screens.rentCollection' })

  const fetcher = async ({ statuses, ...paginate }: FetcherArg<RentCollectionStatus>) => {
    const queryParams = {
      date,
      country: region,
      statuses,
      listingId,
      unitId,
      ...(isPaginated ? paginate : {}),
    }

    return statisticApi.readRentCollectionPerTenantTable({ queryParams })
  }

  const exportFetcher: ExportFetcher<CSVBody, RentCollectionStatus> = (statuses) => {
    const queryParams = {
      date,
      country: region,
      statuses,
      listingId,
      unitId,
    }

    return statisticApi.readRentCollectionCSVData({ queryParams })
  }
  const exportFetcherKey = 'readRentCollectionCSVData'

  return (
    <Flex flexDirection='column' gap={2}>
      {header}
      <PaginatedTable
        queryKey={`rentCollectionPerTenantTable-${user?.id}-${date}`}
        fetcher={fetcher}
        columns={useRentCollectionColumns()}
        pagination={{ perPage: 10, sort: 'status', sortOrder: 1 }}
        showPagination={isPaginated}
        isRowCollapsible={false}
        exportAsCSV={{
          fetcherKey: exportFetcherKey,
          fetcher: exportFetcher,
        }}
        statusGroups={{
          mapper: RentCollectionStatusGroups,
          onSelect,
          selectedStatusGroupName: selectedStatusGroup,
          groups: [
            {
              name: 'all',
              label: tMain('statusGroup.all'),
            },
            {
              name: 'received',
              label: tMain('statusGroup.received'),
              tooltip: (
                <RentCollectionStatusGrouping
                  group={RentCollectionKpiStatusGroups.RECEIVED}
                  groupKey={RentCollectionKpiKey.RECEIVED}
                />
              ),
            },
            {
              name: 'collected',
              label: tMain('statusGroup.collected'),
              tooltip: (
                <RentCollectionStatusGrouping
                  group={RentCollectionKpiStatusGroups.COLLECTED}
                  groupKey={RentCollectionKpiKey.COLLECTED}
                />
              ),
            },
            {
              name: 'processing',
              label: tMain('statusGroup.processing'),
              tooltip: (
                <RentCollectionStatusGrouping
                  group={RentCollectionKpiStatusGroups.PROCESSING}
                  groupKey={RentCollectionKpiKey.PROCESSING}
                />
              ),
            },
            {
              name: 'bad',
              label: tMain('statusGroup.bad'),
              tooltip: (
                <Flex flexDirection='column' gap={2}>
                  <RentCollectionStatusGrouping
                    group={[RentCollectionStatus.failed]}
                    groupKey={RentCollectionKpiKey.FAILED}
                  />
                  <RentCollectionStatusGrouping
                    group={RentCollectionKpiStatusGroups.OVERDUE}
                    groupKey={RentCollectionKpiKey.OVERDUE}
                  />
                </Flex>
              ),
            },
          ],
        }}
      />
    </Flex>
  )
}
