import { CurrencyISO } from '@guiker/money'

import { Price } from '../../../listing/price'

export const Base = {
  price: {
    amount: 0,
    currency: CurrencyISO.CAD,
  } as Price,
  topup: {
    amount: 0,
    currency: CurrencyISO.CAD,
  } as Price,
} as const
