import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { PayInMethodType } from '@guiker/payment-shared'
import { ColumnGridItem } from '@guiker/react-framework'

import { AddCreditCard, AddDirectDebitACH, AddDirectDebitEFT, AddDirectDebitPAD } from './PayInMethods'

export const AddPayInMethod: React.FC = () => {
  const { allowedTypes } = usePayInMethodContext()
  const isMultipleAvailable = allowedTypes.length > 1

  return (
    <>
      {allowedTypes.includes(PayInMethodType.CREDIT_CARD) && (
        <ColumnGridItem md={isMultipleAvailable ? undefined : 2}>
          <AddCreditCard />
        </ColumnGridItem>
      )}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_ACH) && <AddDirectDebitACH />}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_EFT) && <AddDirectDebitEFT />}
      {allowedTypes.includes(PayInMethodType.DIRECT_DEBIT_PAD) && <AddDirectDebitPAD />}
    </>
  )
}
