import { getLocationService, Listing as BaseListing } from '@guiker/base-entity'
import { DateTime, Interval } from '@guiker/date'
import { slugifyLowercase } from '@guiker/lodash'
import { CurrencyISO, money } from '@guiker/money'

import * as Legacy from '../legacy'
import { FloorPlan, PriceOptionStatus, RentalListing, RentalOption } from '../rentalListing'

type TransformArgs = {
  listing: Legacy.Listing
  optionId?: number
  includeAllOptions?: boolean
}

const s3Bucket = 'https://s3.amazonaws.com/guiker.resources'
const CDN = 'https://resources.guiker.com'

const getAvailableInventories = (inventories: Legacy.Inventory[], includeAllOptions = false): Legacy.Inventory[] => {
  const availableInventories: Legacy.Inventory[] = []
  if (!inventories?.length) return availableInventories

  for (const inventory of inventories) {
    if (!inventory.isDeleted && inventory.configurations?.length > 0) {
      const availableConfigs = []

      for (const config of inventory.configurations) {
        const includeEmptyConfig = includeAllOptions || config.options?.length > 0

        if (config.isActive && !config.isDeleted && includeEmptyConfig) {
          const availableOptions = []

          for (const option of config.options) {
            const isAvailableOption =
              option.status === 'AVAILABLE' && DateTime.local() < DateTime.fromISO(option.expiresAt)

            if (!option.isDeleted && (includeAllOptions || isAvailableOption)) {
              availableOptions.push(option)
            }
          }

          if (availableOptions.length > 0 || includeAllOptions) {
            availableConfigs.push({ ...config, options: availableOptions })
          }
        }
      }
      if (availableConfigs.length > 0) {
        availableInventories.push({
          ...inventory,
          configurations: availableConfigs,
        })
      }
    }
  }
  return availableInventories
}

const getUnitAmenities = (
  amenities: Legacy.Amenity[],
): BaseListing.UnitAppliances<boolean> & BaseListing.KitchenAppliances<boolean> => {
  const mappedAmenities =
    amenities?.reduce((result, amenity) => {
      const key = BaseListing.LegacyUnitAmenityMap[amenity.name as Legacy.UnitAmenityName]
      return key ? { ...result, [key]: true } : result
    }, {} as Partial<BaseListing.UnitAppliances<boolean> & BaseListing.KitchenAppliances<boolean>>) || {}

  return {
    washer: !!mappedAmenities.washer,
    dishwasher: !!mappedAmenities.dishwasher,
    fridge: !!mappedAmenities.fridge,
    oven: !!mappedAmenities.oven,
    airConditioner: !!mappedAmenities.airConditioner,
    balcony: !!mappedAmenities.balcony,
    petsAllowedLarge: !!mappedAmenities.petsAllowedLarge,
    petsAllowedSmall: !!mappedAmenities.petsAllowedSmall,
  }
}

const getUtilities = (amenities: Legacy.Amenity[]): BaseListing.Utilities<boolean> => {
  const mappedAmenities =
    amenities?.reduce((result, amenity) => {
      const key = BaseListing.LegacyUtilityAmenityMap[amenity.name as Legacy.UtilityAmenityName]
      return key ? { ...result, [key]: true } : result
    }, {} as Partial<BaseListing.Utilities<boolean>>) || {}

  return {
    wifi: !!mappedAmenities.wifi,
    electricity: !!mappedAmenities.electricity,
    heating: !!mappedAmenities.heating,
    hotWater: !!mappedAmenities.hotWater,
  }
}

const getBuildingAmenities = (amenities: Legacy.Amenity[]): BaseListing.BuildingAmenities<boolean> => {
  const mappedAmenities =
    amenities?.reduce((result, amenity) => {
      const key = BaseListing.LegacyBuildingAmenityMap[amenity.name as Legacy.BuildingAmenityName]
      return key ? { ...result, [key]: true } : result
    }, {} as Partial<BaseListing.BuildingAmenities<boolean>>) || {}

  return {
    bikeParking: !!mappedAmenities.bikeParking,
    communalOutdoorSpace: !!mappedAmenities.communalOutdoorSpace,
    concierge: !!mappedAmenities.concierge,
    elevator: !!mappedAmenities.elevator,
    gym: !!mappedAmenities.gym,
    laundryRoom: !!mappedAmenities.laundryRoom,
    storage: !!mappedAmenities.storage,
    indoorParking: !!mappedAmenities.indoorParking,
    outdoorParking: !!mappedAmenities.outdoorParking,
    pool: !!mappedAmenities.pool,
    recreationRoom: !!mappedAmenities.recreationRoom,
    wheelchairAccessible: !!mappedAmenities.wheelchairAccessible,
    security: false,
  }
}

const getRentalOption = (inventory: Legacy.Inventory, configuration: Legacy.Configuration): RentalOption => {
  return {
    suiteNumber: inventory.suiteNumber,
    type: configuration.type,
    size: configuration.title, // bad naming, but it will map to square footage
    roomCount: {
      bedroom: inventory.bedroomCount,
      bathroom: inventory.bathroomCount,
      kitchen: inventory.kitchenCount,
      livingRoom: inventory.livingroomCount,
    },
    priceOptions:
      configuration.options?.map((option) => ({
        price: {
          amount: money.toAmount(option.price, money.currency[option.currency]).amount,
          currency: option.currency as CurrencyISO,
        },
        legacyOptionId: option.id,
        availablityDate: option.availableFrom,
        expirationDate: option.expiresAt,
        status: option.status as PriceOptionStatus,
        duration: {
          count: Math.floor(
            Interval.fromDateTimes(
              DateTime.fromISO(option.availableFrom),
              DateTime.fromISO(option.expiresAt).plus({ days: 1 }),
            ).length('months'),
          ),
          unit: 'month',
        },
        furnished: option.isFurnished ? 'fully' : 'none',
      })) || [],
    unitAmenities: getUnitAmenities(inventory.amenities),
    utilities: getUtilities(inventory.amenities),
  }
}

const buildRentalOptions = (args: TransformArgs): RentalOption[] => {
  const { listing, optionId, includeAllOptions = false } = args
  const rentalOptions: RentalOption[] = []

  getAvailableInventories(listing.inventories, includeAllOptions)?.forEach((inventory) =>
    inventory.configurations?.forEach((configuration) => {
      const rentalOption = getRentalOption(inventory, configuration)

      if (optionId) {
        const hasOptionById = configuration.options?.some(({ id }) => id === optionId)
        hasOptionById && rentalOptions.push(rentalOption)
      } else {
        rentalOptions.push(rentalOption)
      }
    }),
  )

  return rentalOptions
}

const buildFloorPlans = (rentalOptions: RentalOption[], currency: CurrencyISO) =>
  Object.values(
    rentalOptions.reduce((result, option) => {
      const key = Object.values(option.roomCount).join('-')
      const cached: FloorPlan = result[key] || {
        roomCount: option.roomCount,
        priceRange: {
          min: {
            amount: Math.min(...option.priceOptions.map((o) => o.price.amount)),
            currency,
          },
          max: {
            amount: Math.max(...option.priceOptions.map((o) => o.price.amount)),
            currency,
          },
        },
        sizeRange: {
          min: '',
          max: '',
        },
      }

      return {
        ...result,
        [key]: {
          ...cached,
          priceRange: {
            min: {
              amount: Math.min(...option.priceOptions.map((o) => o.price.amount), cached.priceRange.min.amount),
              currency,
            },
            max: {
              amount: Math.max(...option.priceOptions.map((o) => o.price.amount), cached.priceRange.max.amount),
              currency,
            },
          },
        },
      }
    }, {} as { [key: string]: FloorPlan }),
  )

const buildLeadOption = (rentalOptions: RentalOption[]) => {
  if (rentalOptions.length === 0) return null

  const leadOption = rentalOptions.sort((x, y) => {
    const p1 = Math.min(...x.priceOptions.map((o) => o.price.amount))
    const p2 = Math.min(...y.priceOptions.map((o) => o.price.amount))
    return p1 <= p2 ? -1 : 1
  })[0]

  const { priceOptions, ...attributes } = leadOption

  return {
    ...attributes,
    priceOption: priceOptions?.sort((x, y) => (x.price.amount <= y.price.amount ? -1 : 1))[0],
  }
}

export const transformLegacyListingToListing = (args: TransformArgs): RentalListing => {
  const { listing, optionId, includeAllOptions = false } = args
  const rentalOptions = buildRentalOptions({ listing, optionId, includeAllOptions })

  const locationService = getLocationService()

  const address: RentalListing['address'] = {
    city: slugifyLowercase(listing.city.cityName),
    cityName: listing.cityName,
    country: listing.city.country,
    state: listing.city.state,
    postalCode: listing.zipcode,
    street: listing.streetName,
    streetNumber: listing.streetNumber,
    coordinates: {
      lat: parseFloat(listing.latitude as string),
      lng: parseFloat(listing.longitude as string),
    },
  }

  address.neighbourhood = listing.neighbourhood || locationService.getLocationFromAddress(address)?.neighbourhood?.slug
  const slug = slugifyLowercase(`${address.streetNumber}-${address.street}`)
  const currency = listing.city.currency

  return {
    id: `${listing.id}`,
    title: listing.title,
    description: listing.description,
    externalId: listing.externalId,
    slug: `${listing.id}/${slug}`,
    address,
    rentalOptions,
    floorPlans: buildFloorPlans(rentalOptions, currency),
    leadOption: buildLeadOption(rentalOptions),
    pictures: listing.pictures.map((picture) => ({
      url: picture.pictureName.replace(s3Bucket, CDN),
    })),
    lease: {
      leaseType: listing?.listingType?.key,
    },
    buildingAmenities: getBuildingAmenities(listing.amenities),
    source: {
      name: listing.source.name,
      cityId: listing.source.cityId,
      reference: listing.reference,
    },
    listingOperators: !!listing.listingUsers.length
      ? listing.listingUsers.map((listingUser) => ({
          userId: listingUser.user.id.toString(),
          firstName: listingUser.user.firstName,
          lastName: listingUser.user.lastName,
          email: listingUser.user.email,
          phoneNumber: listingUser.user.phone,
          role: listingUser.role,
          isContactPerson: listingUser.isContactPerson,
          isLeaseContractSigner: listingUser.isLeaseContractSigner,
        }))
      : [],
    status: listing.status,
    isDeleted: listing.isDeleted,
    createdAt: listing.createdAt,
    updatedAt: listing.updatedAt,
  }
}
