import { CurrencyISO } from '@guiker/money'

import { Price } from '../../../listing/price'

export const Base = {
  price: {
    currency: CurrencyISO.USD,
  } as Price,
  topup: {
    amount: null,
    currency: CurrencyISO.USD,
  } as Price,
} as const
