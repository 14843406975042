import { HttpMethod, Payment } from '@guiker/shared-framework'

import { Tax } from '../../entity'
import { userIdPathParamsSchema } from '../schemas'
import { routes as batchChargesRoutes } from './batch-charges'
import { routes as fundingAccountRoutes } from './funding-accounts'
import { routes as invoiceRoutes } from './invoices'
import { paths } from './paths'
import { routes as payInMethodRoutes } from './pay-in-methods'
import {
  CreateDirectDebitACHPayloadSchema,
  createDirectDebitACHPayloadSchema,
  CreateDirectDebitEFTPayloadSchema,
  createDirectDebitEFTPayloadSchema,
} from './schemas'
import { routes as usersRoutes } from './users'

const TAX_PATH = paths.tax.one.base()

const readAllPayInMethodsResponseValidator = (payload: unknown) => Promise.resolve(payload as Payment.PayInMethod[])

export const routes = {
  ...usersRoutes,
  ...batchChargesRoutes,
  ...fundingAccountRoutes,
  ...invoiceRoutes,
  ...payInMethodRoutes,
  createDirectDebitEFT: {
    path: `${paths.paymentMethod.all.withScope('users', ':userId')}/direct-debit/eft`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (params: unknown) => userIdPathParamsSchema.validate(params),
    payloadValidator: (payload: unknown): Promise<Required<CreateDirectDebitEFTPayloadSchema>> =>
      createDirectDebitEFTPayloadSchema.validate(payload),
  },
  createDirectDebitACH: {
    path: `${paths.paymentMethod.all.withScope('users', ':userId')}/direct-debit/ach`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (params: unknown) => userIdPathParamsSchema.validate(params),
    payloadValidator: (payload: unknown): Promise<CreateDirectDebitACHPayloadSchema> =>
      createDirectDebitACHPayloadSchema.validate(payload),
  },
  readAllPayInMethods: {
    path: paths.paymentMethod.all.withScope('user', ':userId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: readAllPayInMethodsResponseValidator,
    pathParamsValidator: (params: unknown) => userIdPathParamsSchema.validate(params),
  },
  readAllTaxes: {
    path: TAX_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as Tax[]),
  },
}
